import axios from 'axios';
const { VUE_APP_LOGGING_API_URL } = process.env;

export const SEVERITY = {
  DEBUG: 'Debug',
  INFORMATION: 'Information',
  WARNING: 'Warning',
  ERROR: 'Error',
  FATAL: 'Fatal'
};

let prevMessage = '';

export const log = ({ message, line = 0, source = '', severity = SEVERITY.DEBUG }) => {
  // Uncomment to disable logging
  // return false;
  const token = window.localStorage.getItem('token');
  if (token && VUE_APP_LOGGING_API_URL && prevMessage !== message) {
    prevMessage = message;
    fetch(`${VUE_APP_LOGGING_API_URL}/Logs`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        appName: 'onboarding-app',
        appType: 'frontend',
        severity: severity,
        messageTemplate: window.location.host.split('.')[0],
        messageTemplateValues: [window.location.href + ' ' + line + ' ' + message],
        ipAddress: '-',
        source: `Javascript${source}`
      })
    });
  }
};
