import fieldTypes from '@forms/const/fieldTypes.js';

export default function backwardsCompatibleForms(form) {
  if (form && form.schema && form.schema.properties) {
    Object.keys(form.schema.properties).map((sectionKey) => {
      const section = form.schema.properties[sectionKey];
      if (section && section.properties) {
        Object.keys(section.properties).map((questionKey) => {
          let question = section.properties[questionKey];

          // Apply title to group if not exist
          if (question['field-type'] === fieldTypes.group && !question.title) {
            question.title = setGroupTitle(question);
          }

          if (question['field-type'] === fieldTypes.group) {
            addKosovoToDefinitions(fieldTypes, question);
          }

          // if group
          if (question['field-type'] === fieldTypes.group) {
            if (!question.description) {
              question.description = 'Group description';
            }
            if (question.data.showTitle === undefined) {
              question.data.showTitle = false;
            }
            if (question.data.showDescription === undefined) {
              question.data.showDescription = false;
            }

            // has questions in group
            if (question.properties) {
              Object.keys(question.properties).map((questionInGroupKey) => {
                let questionInGroup = question.properties[questionInGroupKey];
                updateFileUploadFilesInput(fieldTypes, questionInGroup);
                setGdprTitle(fieldTypes, questionInGroup);
                addKosovoToDefinitions(fieldTypes, questionInGroup);
                updateEmailValidation(fieldTypes, questionInGroup);
                disableManualInputDatePicker(fieldTypes, questionInGroup);
              });
            }
          } else {
            updateFileUploadFilesInput(fieldTypes, question);
            setGdprTitle(fieldTypes, question);
            addKosovoToDefinitions(fieldTypes, question);
            updateEmailValidation(fieldTypes, question);
            disableManualInputDatePicker(fieldTypes, question);
          }
        });
      }
    });
  }
  return form;
}

function setGroupTitle(question) {
  return `Group ${Number(question.index.slice(question.index.lastIndexOf('/') + 1)) + 1}`;
}

// Make form-fileupload accept excel files
function updateFileUploadFilesInput(fieldTypes, question) {
  if (
    question['field-type'] === fieldTypes.questionFile &&
    question.data.availableExtensions &&
    question.data.availableExtensions.indexOf('.xlsx') < 0
  ) {
    question.data.availableExtensions.splice(question.data.availableExtensions.length / 2, 0, '.xlsx');
    question.data.availableExtensions.push('.XLSX');
  }
  if (
    question['field-type'] === fieldTypes.questionFile &&
    question.data.availableExtensions &&
    question.data.availableExtensions.indexOf('.ppt') < 0
  ) {
    question.data.availableExtensions.splice(question.data.availableExtensions.length / 2, 0, '.ppt');
    question.data.availableExtensions.push('.ppt');
  }
  if (
    question['field-type'] === fieldTypes.questionFile &&
    question.data.availableExtensions &&
    question.data.availableExtensions.indexOf('.pptx') < 0
  ) {
    question.data.availableExtensions.splice(question.data.availableExtensions.length / 2, 0, '.pptx');
    question.data.availableExtensions.push('.pptx');
  }
  if (
    question['field-type'] === fieldTypes.questionFile &&
    question.data.maxSize &&
    question.data.maxSize === 50000000
  ) {
    question.data.maxSize = 10000000;
  }
}

// Make new email validation
function updateEmailValidation(fieldTypes, question) {
  const emailPattern = `^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,6}))$`;
  if (question['field-type'] === fieldTypes.questionEmail && question.pattern !== emailPattern) {
    question.pattern = emailPattern;
  }
}

// Make gdpr title
function setGdprTitle(fieldTypes, question) {
  if (question['field-type'] === fieldTypes.questionGDPR && question.title !== 'GDPR') {
    question.title = 'GDPR';
  }
}

// Add kosovo into the countries list validation
function addKosovoToDefinitions(fieldTypes, question) {
  if (question['field-type'] === fieldTypes.questionAddress && !question.properties.country_code.enum.includes('XK')) {
    question.properties.country_code.enum.push('XK');
  }
  if (question['field-type'] === fieldTypes.group) {
    if (question.items && question.items.properties && Object.keys(question.items.properties).length > 0) {
      const keys = Object.keys(question.items.properties).filter(
        (e) => question.items.properties[e]['field-type'] === 'form-address-field'
      );
      keys.forEach((key) => {
        if (!question.items.properties[key].properties.country_code.enum.includes('XK')) {
          question.items.properties[key].properties.country_code.enum.push('XK');
        }
      });
    }
  }
}

function disableManualInputDatePicker(fieldTypes, question) {
  if (question['field-type'] === fieldTypes.questionDate && question.data.disableManualInput === undefined) {
    question.data.disableManualInput = false;
  }
}
