import Vue from 'vue';
import { english } from './english';
const { VUE_APP_ADMIN_API_URL, VUE_APP_TRANSLATION_API_URL } = process.env;
const { defaultLanguage, i18nTimestamp } = window.localStorage;

import ElementUI from 'element-ui';

import axios from 'axios';
import setLanguage from './setDefaultElementIOLanguage';

const token = window.localStorage.admin_token;

let manifest = window.localStorage.getItem('language_manifest')
  ? JSON.parse(window.localStorage.getItem('language_manifest'))
  : undefined;

const manifestUrl = `${VUE_APP_TRANSLATION_API_URL}/translations/metadata?project=Frontend`;
const languageUrl = `${VUE_APP_TRANSLATION_API_URL}/translations/latest?project=Frontend`;

const adminInstance = axios.create({
  baseURL: VUE_APP_ADMIN_API_URL,
  timeout: 180000,
  headers: {
    Authorization: token ? `Bearer ${token}` : null
  }
});

const getManifest = () => {
  return new Promise((resolve, reject) => {
    fetch(manifestUrl)
      .then((e) => {
        e.json().then((result) => {
          resolve(result);
          window.localStorage.setItem(`language_manifest`, JSON.stringify(result));
        });
      })
      .catch(() => {
        resolve({
          timestamp: 0,
          content: {
            ar: ['/content/master/i18n/ar-DZ.json'],
            hy: ['/content/master/i18n/hy.json'],
            bg: ['/content/master/i18n/bg-BG.json'],
            'zh-CN': ['/content/master/i18n/zh-CN.json'],
            'zh-TW': ['/content/master/i18n/zh-TW.json'],
            cs: ['/content/master/i18n/cs-CZ.json'],
            da: ['/content/master/i18n/da-DK.json'],
            nl: ['/content/master/i18n/nl-NL.json'],
            en: ['/content/master/i18n/en-US.json'],
            fi: ['/content/master/i18n/fi-FI.json'],
            fr: ['/content/master/i18n/fr-FR.json'],
            ka: ['/content/master/i18n/ka.json'],
            de: ['/content/master/i18n/de-DE.json'],
            el: ['/content/master/i18n/el-GR.json'],
            hu: ['/content/master/i18n/hu-HU.json'],
            it: ['/content/master/i18n/it-IT.json'],
            ja: ['/content/master/i18n/ja-JP.json'],
            kk: ['/content/master/i18n/kk-KZ.json'],
            ko: ['/content/master/i18n/ko-KO.json'],
            ky: ['/content/master/i18n/ky.json'],
            no: ['/content/master/i18n/nb-NO.json'],
            pl: ['/content/master/i18n/pl-PL.json'],
            pt: ['/content/master/i18n/pt-PT.json'],
            'pt-BR': ['/content/master/i18n/pt-BR.json'],
            ro: ['/content/master/i18n/ro-RO.json'],
            ru: ['/content/master/i18n/ru-RU.json'],
            sr: ['/content/master/i18n/sr-LATN.json'],
            sk: ['/content/master/i18n/sk-SK.json'],
            sl: ['/content/master/i18n/sl-SL.json'],
            es: ['/content/master/i18n/es-ES.json'],
            sv: ['/content/master/i18n/sv-SE.json'],
            tr: ['/content/master/i18n/tr-TR.json'],
            uk: ['/content/master/i18n/uk-UA.json'],
            vi: ['/content/master/i18n/vi-VN.json']
          }
        });
      });
  });
};

const getLanguage = (lan, timestamp) => {
  const idReference = {
    'ko-KR': 'ko-KO',
    'es-ES419': 'es-ES'
  };

  const language = Object.entries(manifest.content).find(([key, value]) => value[0].includes(lan))
    ? Object.entries(manifest.content).find(([key, value]) => value[0].includes(lan))[0]
    : 'en';
  return new Promise((resolve, reject) => {
    fetch(
      `${languageUrl}&LanguageCode=${idReference[language] ? idReference[language] : language}${
        timestamp ? '&Timestamp=' + timestamp : ''
      }`
    )
      .then((e) => {
        if (e.status === 304) {
          resolve(false);
        }
        e.json().then((result) => {
          resolve(result.translations);
          if (timestamp) {
            window.localStorage.setItem(`i18nTimestamp`, String(result.timestamp));
          }
        });
      })
      .catch((res) => {
        resolve(english);
      });
  });
};

export const updateLanguage = async (language, timestamp) => {
  const i18nLanguage = await getLanguage(language);
  window.localStorage.setItem(`i18n${language}`, JSON.stringify(i18nLanguage));
  Vue.i18n.add(language, i18nLanguage);
  Vue.i18n.set(language);
};

const i18nSetup = async () => {
  let selectedLanguage = defaultLanguage;
  Vue.i18n.add('en-US', english);
  Vue.i18n.set('en-US'); // default
  Vue.i18n.fallback('en-US');

  if (!selectedLanguage) {
    if (window.localStorage.getItem('user') !== null) {
      let user = JSON.parse(window.localStorage.getItem('user'));
      selectedLanguage = user.language;
      window.localStorage.setItem('defaultLanguage', selectedLanguage);
    }
    if (window.localStorage.getItem('admin_user') !== null) {
      let user = JSON.parse(window.localStorage.getItem('admin_user'));
      selectedLanguage = user.language;
      window.localStorage.setItem('defaultLanguage', selectedLanguage);
    }
    if (token && window.localStorage.getItem('admin_user') !== null) {
      const { admin_user } = window.localStorage;
      let user = JSON.parse(admin_user);
      adminInstance
        .get(`/v1/companies/${user.companyId}/templates/${user.templateId}/languages`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then((res) => {
          const language = res.data.find((e) => e.default);
          selectedLanguage = language.localeCode;
          window.localStorage.setItem('defaultLanguage', selectedLanguage);
        });
    } else {
      selectedLanguage = 'en-US';
      window.localStorage.setItem('defaultLanguage', selectedLanguage);
    }
  }

  if (manifest && window.localStorage.getItem(`i18n${selectedLanguage}`)) {
    const i18nLanguage = await getLanguage(selectedLanguage || 'en-US', i18nTimestamp);
    if (i18nLanguage) {
      window.localStorage.setItem(`i18n${selectedLanguage}`, JSON.stringify(i18nLanguage));
    }
    Vue.i18n.add(selectedLanguage, JSON.parse(window.localStorage.getItem(`i18n${selectedLanguage}`)));
    Vue.i18n.set(selectedLanguage); // default
  } else {
    manifest = await getManifest();

    const i18nLanguage = await getLanguage(selectedLanguage || 'en-US');
    window.localStorage.setItem(`i18nTimestamp`, String(manifest.timestamp));
    window.localStorage.setItem(`i18n${selectedLanguage || 'en-US'}`, JSON.stringify(i18nLanguage));
    Vue.i18n.add(
      selectedLanguage || 'en-US',
      JSON.parse(window.localStorage.getItem(`i18n${selectedLanguage || 'en-US'}`))
    );
    Vue.i18n.set(selectedLanguage || 'en-US'); // default
  }

  Vue.use(ElementUI, { locale: setLanguage(selectedLanguage) });

  return;
};

i18nSetup();
