import Vue from 'vue';

function validateBelgianId(n) {
  const pattern = /^\d{11}$/; // check for digits and length (should be 11 digits)
  if (!n.match(pattern)) return false;

  var checkDigit = n.substr(n.length - 2, 2);
  var modFunction = function(nr) {
    return 97 - (nr % 97);
  };
  var nrToCheck = parseInt(n.substr(0, 9));

  // first check without 2
  if (modFunction(nrToCheck) == checkDigit) return true;

  // then check with 2 appended for y2k+ births
  nrToCheck = parseInt('2' + n.substr(0, 9));

  return modFunction(nrToCheck) == checkDigit;
}

export default {
  getMessage(field, params, data) {
    return (data && data.message) || Vue.i18n.translate('validation.belgianid');
  },
  validate(value) {
    return new Promise((resolve) => {
      resolve({
        valid: validateBelgianId(
          value
            .replace(/\//g, '')
            .replace(/-/g, '')
            .replace(/\./g, '')
            .replace(/ /g, '')
        ),
        data: value !== 'trigger' ? undefined : { message: 'Not this value' }
      });
    });
  }
};
