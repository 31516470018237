<template>
  <div>
    <sweet-modal :title="$t('forms.insertComponentModalTitle')" hide-footer ref="formModal" :blocking="true">
      <div v-if="loading">
        <loader></loader>
      </div>
      <div v-else>
        <form @submit.prevent>
          <div class="row mb-2 border-bottom">
            <div class="col-12">
              <div class="form-group">
                <h6>{{ 'forms.insertComponentTitle' | translate }}</h6>
                <el-collapse v-model="activeName" accordion>
                  <el-collapse-item name="1">
                    <template slot="title">
                      <div class="accordion-title-image">
                        <i class="material-icons">repeat</i>
                      </div>
                      <div class="accordion-title-text-container">
                        <div class="title">{{ 'forms.mostUsed' | translate }}</div>
                        <div class="subtitle">{{ 'forms.mostUsedComponent' | translate }}</div>
                      </div>
                    </template>
                    <div class="item-container">
                      <div
                        class="item-question-element"
                        @click="select('openAnswer')"
                        v-bind:class="{ selected: selected === 'openAnswer' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">format_size</i>
                        </div>
                        <div class="text">{{ 'forms.openAnswer' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('choice')"
                        v-bind:class="{ selected: selected === 'choice' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">radio_button_checked</i>
                          <i class="material-icons">check_box</i>
                          <i class="material-icons">arrow_drop_down_circle</i>
                        </div>
                        <div class="text">{{ 'forms.choice' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('rating')"
                        v-bind:class="{ selected: selected === 'rating' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">star_rate</i>
                          <i class="material-icons">insert_emoticon</i>
                          <i class="rating-number">1</i>
                        </div>
                        <div class="text">{{ 'forms.rating' | translate }}</div>
                      </div>
                      <div
                        v-if="!fromGroup"
                        class="item-question-element"
                        @click="select('group')"
                        v-bind:class="{ selected: selected === 'group' }"
                      >
                        <div class="accordion-item-image">
                          <div class="dashed">
                            <i class="material-icons">radio_button_checked</i>
                            <i class="material-icons">text_fields</i>
                            <i class="material-icons">star</i>
                          </div>
                        </div>
                        <div class="text">{{ 'forms.group' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('fileAnswer')"
                        v-bind:class="{ selected: selected === 'fileAnswer' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">cloud_upload</i>
                        </div>
                        <div class="text">{{ 'form.upload' | translate }}</div>
                      </div>
                    </div>
                  </el-collapse-item>
                  <el-collapse-item name="2">
                    <template slot="title">
                      <div class="accordion-title-image">
                        <i class="material-icons">check</i>
                      </div>
                      <div class="accordion-title-text-container">
                        <div class="title">{{ 'forms.presetFields' | translate }}</div>
                        <div class="subtitle">{{ 'forms.presetFieldsSubtitle' | translate }}</div>
                      </div>
                    </template>
                    <div class="item-container">
                      <div
                        class="item-question-element"
                        @click="select('dateField')"
                        v-bind:class="{ selected: selected === 'dateField' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">calendar_today</i>
                        </div>
                        <div class="text">{{ 'forms.dateField' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('phoneField')"
                        v-bind:class="{ selected: selected === 'phoneField' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">local_phone</i>
                        </div>
                        <div class="text">{{ 'forms.phoneField' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('ibanField')"
                        v-bind:class="{ selected: selected === 'ibanField' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">credit_card</i>
                        </div>
                        <div class="text">{{ 'forms.ibanField' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('countryField')"
                        v-bind:class="{ selected: selected === 'countryField' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">flag</i>
                        </div>
                        <div class="text">{{ 'forms.countryField' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('addressField')"
                        v-bind:class="{ selected: selected === 'addressField' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">home</i>
                        </div>
                        <div class="text">{{ 'forms.addressField' | translate }}</div>
                      </div>
                      <div
                        v-if="!fromGroup"
                        class="item-question-element"
                        @click="select('GDPRField')"
                        v-bind:class="{ selected: selected === 'GDPRField' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">lock</i>
                        </div>
                        <div class="text">{{ 'forms.GDPRField' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('emailField')"
                        v-bind:class="{ selected: selected === 'emailField' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">email</i>
                        </div>
                        <div class="text">{{ 'forms.emailField' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('RegExp')"
                        v-bind:class="{ selected: selected === 'RegExp' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">code</i>
                        </div>
                        <div class="text">{{ 'forms.RegExpField' | translate }}</div>
                      </div>
                      <div
                        class="item-question-element"
                        @click="select('numberField')"
                        v-bind:class="{ selected: selected === 'numberField' }"
                      >
                        <div class="accordion-item-image">
                          <i class="material-icons">code</i>
                        </div>
                        <div class="text">{{ 'forms.numberField' | translate }}</div>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </div>
            </div>
          </div>
          <div class="float-right">
            <button class="btn btn-secondary mr-2" @click="hideFormModal">{{ 'general.cancel' | translate }}</button>
            <button class="btn btn-primary" type="submit" :disabled="!selected" @click="insertComponent">
              {{ 'forms.insertComponent' | translate }}
            </button>
          </div>
        </form>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { uuid } from 'vue-uuid';
import Tags from '@lp/components/Tags.vue';
import notifications from '@shared/mixins/notifications';
import { mapGetters } from 'vuex';
import fieldTypes from '@forms/const/fieldTypes.js';
import definitions from '@forms/const/definitions.js';
import cloneDeep from 'lodash/cloneDeep';
import { SweetModal } from 'sweet-modal-vue';

export default {
  mixins: [notifications],
  components: {
    Tags,
    SweetModal
  },
  data() {
    return {
      activeName: '1',
      selected: null
    };
  },
  props: {
    fromGroup: {
      type: String,
      default: undefined
    }
  },
  computed: {
    ...mapGetters('loading', ['loading']),
    ...mapGetters('formBuilder', ['createQuestionIdInSection', 'schema', 'selectedSection', 'selectedItem'])
  },
  methods: {
    hideFormModal() {
      this.activeName = null;
      this.selected = null;
      this.$refs.formModal.close();
    },
    select(item) {
      this.selected = item;
    },
    getSupportedFileExtensions() {
      const supportedFileExtensions = [
        '.txt',
        '.pdf',
        '.doc',
        '.docx',
        '.xml',
        '.jpg',
        '.jpeg',
        '.png',
        '.zip',
        '.ppt',
        '.pptx',
        '.xlsx'
      ];
      const uppercasedSupportedFileExtensions = supportedFileExtensions.map((extension) => extension.toUpperCase());
      return [...supportedFileExtensions, ...uppercasedSupportedFileExtensions];
    },
    insertComponent() {
      const index = this.$el.closest('[index]').getAttribute('index');
      const elems = this.fromGroup
        ? Math.max(
            -1,
            ...[...this.$el.closest('.group').querySelector('.group-content-wrapper').children].map((e) =>
              e.getAttribute('id')
                ? Number(
                    e
                      .getAttribute('id')
                      .split('/')
                      .pop()
                  )
                  ? Number(
                      e
                        .getAttribute('id')
                        .split('/')
                        .pop()
                    )
                  : 0
                : 0
            )
          ) + 1
        : this.createQuestionIdInSection;
      const key = `${this.fromGroup ? this.fromGroup.concat('.') : ''}${
        this.selected === 'group'
          ? 'form-group'
          : this.fromGroup
          ? this.fromGroup + '/' + 'form-question'
          : 'form-question'
      }/${elems}`;
      let order;
      if (this.fromGroup) {
        order = this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties
          ? Math.max(
              -1,
              ...Object.keys(
                this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties
              ).map(
                (e) =>
                  this.schema.properties[this.selectedItem.sectionId].properties[this.selectedItem.groupId].properties[
                    e
                  ].data.order
              )
            ) + 1
          : 0;
      } else {
        order = this.schema.properties[this.selectedItem.sectionId].properties
          ? Math.max(
              -1,
              ...Object.keys(this.schema.properties[this.selectedItem.sectionId].properties).map(
                (e) => this.schema.properties[this.selectedItem.sectionId].properties[e].data.order
              )
            ) + 1
          : 0;
      }
      const fromGroup = this.fromGroup;

      let question;
      const questionIndex = `${fieldTypes.formSection}/${index}.${key}`;
      const questionTitle = `<b>${this.$t('forms.question')}</b>`;
      switch (this.selected) {
        case 'openAnswer':
          // schema open answer question
          question = {
            'field-type': fieldTypes.questionOpenAnswer,
            type: 'string',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              editMode: false,
              order: order,
              placeholder: this.$t('forms.question'),
              showTitle: true,
              showDescription: false,
              shortAnswer: true,
              maxCharacters: 120,
              requireValidation: false
            }
          };

          break;
        case 'choice':
          // schema question
          question = {
            'field-type': fieldTypes.questionChoice,
            type: 'string',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              editMode: true,
              enableOtherOption: false,
              order: order,
              type: 'radio',
              showTitle: true,
              showDescription: false
            },
            enum: [`${this.$t('form.option')}1`, `${this.$t('form.option')}2`]
          };
          break;
        case 'rating':
          question = {
            'field-type': fieldTypes.questionRating,
            type: 'number',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              editMode: true,
              order: order,
              minLabel: this.$t('form.veryBad'),
              maxLabel: this.$t('form.veryGood'),
              showTitle: true,
              showDescription: false,
              showLabels: true
            },
            variant: 0,
            value: 0,
            min: 3,
            max: 5
          };
          break;
        case 'fileAnswer':
          // schema file input
          question = {
            'field-type': fieldTypes.questionFile,
            type: 'array',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            items: {
              type: 'string'
            },
            data: {
              editMode: true,
              order: order,
              showTitle: true,
              showDescription: false,
              fileType: 'txt',
              maxFiles: 1,
              maxSize: 10000000,
              availableExtensions: this.getSupportedFileExtensions()
            }
          };
          break;
        case 'dateField':
          // schema date input
          question = {
            'field-type': fieldTypes.questionDate,
            type: 'string',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              placeholder: 'Question',
              editMode: true,
              order: order,
              showTitle: true,
              showDescription: false,
              disableManualInput: false
            }
          };
          break;
        case 'phoneField':
          // schema phone input
          question = {
            'field-type': fieldTypes.questionPhone,
            type: 'string',
            pattern: '^(\\+)?([ 0-9]){10,16}$',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              placeholder: this.$t('forms.question'),
              editMode: true,
              order: order,
              showTitle: true,
              showDescription: false
            }
          };
          break;
        case 'countryField':
          question = {
            'field-type': fieldTypes.questionCountry,
            type: 'string',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              placeholder: this.$t('forms.country'),
              editMode: true,
              order: order,
              showTitle: true,
              showDescription: false
            }
          };
          break;
        case 'addressField':
          question = {
            'field-type': fieldTypes.questionAddress,
            type: 'object',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            properties: definitions.address.properties,
            required: definitions.address.required,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              editMode: true,
              order: order,
              showTitle: true,
              showDescription: false,
              displayAdminArea: false,
              displayAddress2: false
            }
          };
          break;
        case 'ibanField':
          question = {
            'field-type': fieldTypes.questionIban,
            type: 'string',
            pattern:
              '^[a-zA-Z]{2}[0-9]{2}\\s?[a-zA-Z0-9]{4}\\s?[0-9]{4}\\s?[0-9]{3}([a-zA-Z0-9]\\s?[a-zA-Z0-9]{0,4}\\s?[a-zA-Z0-9]{0,4}\\s?[a-zA-Z0-9]{0,4}\\s?[a-zA-Z0-9]{0,3})?$',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              placeholder: this.$t('forms.question'),
              editMode: true,
              order: order,
              showTitle: true,
              showDescription: false
            }
          };
          break;
        case 'GDPRField':
          question = {
            'field-type': fieldTypes.questionGDPR,
            type: 'boolean',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: 'GDPR',
            const: true,
            data: {
              label: this.$t('form.GDPR.label'),
              gdprText: this.$t('form.GDPR.text'),
              editMode: true,
              order: order,
              showTitle: false,
              showDescription: false,
              showGdprText: true
            }
          };
          break;
        case 'RegExp':
          question = {
            'field-type': fieldTypes.questionRegExp,
            type: 'string',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            pattern: '',
            data: {
              placeholder: this.$t('forms.question'),
              error: '',
              editMode: true,
              order: order,
              showTitle: true,
              showDescription: false
            }
          };
          break;
        case 'numberField':
          question = {
            'field-type': fieldTypes.questionNumber,
            type: 'number',
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              placeholder: this.$t('forms.question'),
              editMode: true,
              order: order,
              showTitle: true,
              showDescription: false,
              showMin: false,
              showMax: false,
              minValue: 0,
              maxValue: 100
            }
          };
          break;
        case 'emailField':
          question = {
            'field-type': fieldTypes.questionEmail,
            type: 'string',
            pattern: `^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,6}))$`,
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: questionTitle,
            description: this.$t('labels.settingDescription'),
            data: {
              placeholder: this.$t('forms.question'),
              editMode: true,
              order: order,
              showTitle: true,
              showDescription: false
            }
          };
          break;
        case 'group':
          // schema group
          question = {
            'field-type': fieldTypes.group,
            $id: `#/properties/${fieldTypes.formSection}/${index}/properties/${key}`,
            index: questionIndex,
            title: `${this.$t('forms.group')} ${Number(questionIndex.slice(questionIndex.lastIndexOf('/') + 1)) + 1}`,
            description: this.$t('labels.settingDescription'),
            type: 'array',
            maxItems: 1,
            items: {
              type: 'object',
              required: []
            },
            data: {
              showTitle: false,
              showDescription: false,
              editMode: true,
              order: order,
              repeatable: false,
              buttonText: this.$t('form.group.addAnotherElement')
            }
          };
          break;
        default:
          this.hideFormModal();
          break;
      }

      const dependentGroup = this.$store.getters['formBuilder/dependencyQuestionDataById'](fromGroup);
      this.$store.commit('formBuilder/addFormQuestion', { question, index, key, fromGroup, dependentGroup });
      this.hideFormModal();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';

.rating-number {
  background-color: #b5bcc1;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  text-align: center;
  padding-right: 1px;
  color: white;
  margin: 4px;
}

.form-group {
  text-align: left;
  box-sizing: border-box;
  width: 100%;

  .accordion-title-image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
    width: 120px;
    border: 1px solid #d7e0e8;
    border-radius: 4px;
    color: #b5bcc1;
  }

  .accordion-title-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
    .title {
      height: 16px;
      width: 240px;
      color: #021c2c;
      font-size: 15px;
      font-weight: 500;
      line-height: 16px;
    }
    .subtitle {
      height: 16px;
      margin-top: 4px;
      width: 240px;
      color: rgba(2, 28, 44, 0.5);
      font-size: 12px;
      margin-top: 6px;
      line-height: 16px;
    }
  }

  .item-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 5px;
    .item-question-element {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 4px 4px;
      padding: 8px;
      height: 128px;
      width: 142px;
      border: 1px solid rgba(2, 28, 44, 0.15);
      border-radius: 5px;
      cursor: pointer;

      &.selected {
        background-color: rgba(2, 28, 44, 0.03);
        .accordion-item-image {
          color: #021c2c;

          div.dashed {
            border-color: #021c2c;
          }
        }
        .rating-number {
          background-color: #021c2c;
        }
      }

      .accordion-item-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        width: 120px;
        background-color: #ffffff;
        border: 1px solid #d7e0e8;
        border-radius: 4px;
        color: #b5bcc1;

        div.dashed {
          border: 2px #b5bcc1 dotted;
        }

        .material-icons {
          width: 24px;
          margin: 4px;
          vertical-align: middle;
        }

        .numerical {
          &.one {
            font-size: 15px;
            vertical-align: bottom;
          }
          &.two {
            font-size: 12px;
            vertical-align: bottom;
          }
          &.three {
            font-size: 10px;
            vertical-align: bottom;
          }
        }
      }
      .text {
        margin-top: 16px;
        color: #021c2c;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
/deep/ .el-collapse {
  border-bottom: unset;
  border-top: unset;
}
/deep/ .el-collapse-item__header {
  display: flex;
  flex-direction: row;
  height: 88px;
  padding: 8px;
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
  border-top: 1px solid #ebeef5;
  margin: 4px 0;
  background-color: white;
}
/deep/ .el-collapse-item__header.is-active {
  margin: 0;
}
/deep/ .el-collapse-item__wrap {
  border-left: 1px solid #ebeef5;
  border-right: 1px solid #ebeef5;
}
</style>
